export const lang = {
  //導航
  openaccount: '開設帳戶',
  login: '帳號登陸',
  service: '服務內容',
  product: '產品詳情',
  support: '技術支援',
  download: '相關下載',
  promotions: '活动',
  //Tab Bar
  statementline1: 'Finger Trading是由George Home Ltd開發維護，和Jet Profit Group(AU)共同經營的一款線上金融資訊及交易軟體，旨在為全球的投資人提供更多的可選性，及優質的服務。',
  statementline2: '我們的收入來源為廣告和商家的API使用及維護費，我們不會加收您的任何費用。',
  statementline3: '本公司不提供任何的交易產品，您的資金及交易均由接入的金融服務商API/介面完成，你在操作Finger的同時，也可以直接下載並登陸金融服務商的交易系統進行交易。',
  statementline4: '任何聲明的證明檔和統計資料均將根據要求提供。顯示的交易代碼僅作演示計，不旨在構成任何推薦。',
  statementline5: '線上交易股票、期權、期貨、外匯、外國證券和固定收益產品可能存在巨大損失風險。',
  statementline6: '期權交易涉及風險，並非適合所有投資者。更多資訊，請閱讀"標準期權的特徵和風險"。索取複印本，請訪問',
  statementline7: '保證金交易僅適用于可承受高風險的專業投資者。您的損失可能會大於您的初始投資。有關保證金借貸利率的詳細資訊，請參見',
  statementline8: '證券期貨具有高風險，並非適合所有投資者。您的損失可能會大於您的初始投資。開始交易證券期貨之前，請閱讀證券期貨風險披露聲明。',
  statementline9: '外匯交易存在高風險損失。外匯交易的結算日期可能因不同時區和銀行節日而改變。當跨外匯交易市場交易時，可能需要借貸資金來結算外匯交易。當計算跨市場交易的交易成本時，借貸資金的利率也是必須要考慮的因素。',
  statementline10: '有關Jet Profit Group的資訊，請參見',
  statementline11: '有關George Home的資訊，請參見',
  statementline12: '以下是已經接入Finger API的金融服務提供者：',
  statementline13: '你可以在系統中切換你想要的服務商為你提供服務，同時你也可以跳過Finger直接通過他們的交易軟體進行操作',
  tabmenu_title1: '帳戶',
  tabmenu_title2: '支持',
  tabmenu_title3: '相關服務',
  tabmenu_title4: '軟體&下載',
  tabmenu_title5: '關注我們',
  accounttype: '帳戶類型',
  freeuse: '免費試用',
  console: '相關控制台',
  graneback: '技術回饋',
  personaluser: '個人用戶',
  Institutionalusers: '機構用戶',
  broker: '經紀人',
  tradingsystem: '交易軟體',
  relateddocuments: '相關文檔',
  APIaccess: 'API接入',
  Login: '帳號登陸',


  //首頁第一行
  index_line_1_title: '讓我們與您一起發現全球的機遇',
  index_line_1_text: '通過一個綜合帳戶在全球範圍內投資股票、期權、期貨、貨幣、債券和基金。以多種貨幣為您的帳戶提供資金，並以多種貨幣計價的交易資產。每天24小時，每週6天獲取市場資料。',
  markets: '市場數量',
  countries: '國家數量',
  currencies: '貨幣品種',
  //INDEX-line2
  SafetyAndStability: '安全與穩定',
  LowCosts: '低交易成本',
  GlobalAccess: '全球訪問',
  PremierTechnology: '強力的技術支援',
  SafetyAndStabilityText: '我們採用封閉的開發模式，你的每次操作多達五次核對，確保您的資金和交易安全。',
  LowCostsText: '我們透明、低廉的傭金和融資利率使成本最小化，保證了您的回報最大化。',
  GlobalAccessText: '從一個綜合帳戶投資全球的股票、期權、期貨、貨幣、債券和基金。',
  PremierTechnologyText: "Finger強大的技術套件説明您優化您的交易速度和效率，並執行複雜的投資組合分析。",
  //index-line3
  Fingeristhekey: 'Finger是你通往世界的鑰匙',
  GlobalAPIProgram: "全球'API'計畫",
  IntegratedCashManagement: '一體化資產管理',
  EffectiveSocial: '溝通與交流',
  line3_text_1: '我們的API計畫不斷的受到全球金融服務提供者的關注和加入，任何地區的不同類型金融機構可以通過我們提供的API不斷的加入我們的平臺，為您提供最新最快的金融服務及資訊',
  line3_text_2: '我們是一個公開的平臺，你可以把我們理解為一個超級市場，而金融商為供應商，為了獲得我們的產品展出和流量，他們不得不提供更有競爭力的價格',
  line3_text_3: '我們也針對銀行提供了我們的API介面，所以我們的現金管理是一體化的，您的資金會通過銀行之間的API自動以最快的速度去到您需要的地方',
  line3_text_4: '我們的產品設計是封閉式的，並且您的操作每一步都需要通過7層驗證才會進入與金融商的API驗證，它保證了您的交易安全和資訊安全',
  line3_text_5: '我們提供了高效的用戶社交功能，同時也有大量的專業人士活躍在我們的平臺，並開放了新聞API介面為您提供最新的全球資訊',
  line3_text_6: '我們在全球有多個有豐富經驗的技術團隊作為支援，專業高效的為您提供全方位的服務，如果您有針對服務的意見，可以通過點擊與我們聯繫',
  //index-line4
  OurPartners: '合作夥伴',
  //index-line5
  line5_text1: '我非常喜歡FingerTrading帶給我的方便，一個帳戶就能買到全球的股票，未來我非常看到印度的經濟，並想購入印度的優良資產，FingerTrading有效的幫助我在倫敦也能隨時買入和賣出，並且隨時能和印度的投資者交流溝通，能有效的瞭解印度的真實情況，並且交到了很多優質的朋友，現在Finger已經成為我生活中不可或缺的一部分了。',
  line5_text2: '我每天需要動很多手術，工作十分繁忙，完全沒有時間去完成繁瑣的開戶和過多的操作，Finger Trading幫助了我，提交一次資料就在全球都開了戶，我能很方便的交易任何我想交易的 產品，同時簡單的操作也讓我能很快的上手，我愛FInger Trading！！！',
  line5_text3: '我是一名投資銀行的投資經理，每天與這些資料和資訊打交道，以前我需要去很多不同的網站，下載很多不同的APP去收集資訊，但自從加入了Finger，我感覺我的手指可以觸摸都世界 任何角落，我能安心的在我美國的辦公室裡操作亞洲的投資，上午還在亞洲證券市場，下午也許會去歐洲，又可能回來美國，FInger幫助了我！',
  line5_text4: '我是一個藝術工作者，生活在倫敦，發達地區的投資機會已經十分有限了，世界上每個國家和地區所走的路都差不多，先是歐美，再是亞洲，資本和機會都在不停的轉動，我一直在想 能夠投資那些新聞裡的發展中國家，比如中國和印度，我相信他們能幫助我提升我的財富。',


  //登陸窗口
  next: '下一步',
  learn: '瞭解更多',
  signin: '登陸帳號',
  signup: '註冊帳號',
  CreateAccount: '新建一個帳號',
  oruseaccount: '或者用您的帳號登陸',
  Email: '電子郵箱',
  verificationcode: "verification code",
  password: '輸入密碼',
  ConfirmPassword: "確認密碼",
  forgotpassword: '忘記密碼',
  hello: '尊敬的用戶，你好',
  enteryourdetails: '請輸入您的個人資料，並開始與我們的旅程',
  WelcomeBack: '歡迎回來！',
  KeepConnected: '為了與我們保持聯繫，請登錄您的個人資訊',
  Oruseyouremailforregistration: '或者使用你的郵箱來註冊',
  erroremail1: "请输入邮箱地址",
  erroremail2: "请输正确的邮箱格式:xxx@xxx.xxx",
  errorpassword1: "请输入您的密码",
  errorpassword2: "至少8个字符，至少1个大写字母，1个小写字母和1个数字,不能包含特殊字符",
  errorpassword3: "两次输入不一样!请重新确认",
  //Service
  Taptonext: "點擊下一步",
  whatcanido: '我們提供以下的服務：',
  titleService_1_1: "我是一名投資者",
  titleService_1_2: "我是金融機構",
  titleService_1_3: "我是一名銷售商",
  titleService_1_4: "我要交易軟體",
  titleService_2_1: '我想通過Finger來服務全球使用者',
  titleService_2_2: '我想接入Finger的API來為全球使用者提供服務',
  titleService_2_3: '我想説明Finger做某些地區的推廣',
  titleService_2_4: "我喜歡並想購買Finger的交易軟體",
  titleService_3_1: "註冊成為我們的會員需要三步, '用你的手指，掌握世界'",
  titleService_3_2: '我們非常歡迎世界各地優質的金融服務商和供應商加入我們的API計畫，我們會為你在全球推廣你的品牌',
  titleService_3_3: '我們已經為你準備了一個非常不錯的利潤回報和推廣計畫，但你任需要通過我們的資格審查',
  titleService_3_4: 'FInger Trading是一個交易作業系統，我們擁有10年的金融服務經驗，為全球400多家金融機構提供軟體和技術支援。',
  //Support
  KNOWLEDGEBASE: '基礎知識',
  BROKERS: '關於經銷商',
  FINANCINGINSTITUTION: '關於金融機構',
  CONTACTUS: '聯繫我們',
  Howhelpyou: '你需要哪方面的説明?',
  Topics: '求助分類',
  GeneralandFAQs: '常見問題',
  Service: '關於服務',
  Products: '關於產品',
  Feesandprices: '費用和價格',
  MyAccount: '關於帳戶',
  CheckoutandBill: '付款和帳單',
  Relevantlaws: '相關法律',
  APIrules: 'API規則',
  Contactus: '聯繫我們',
  Yourquestion: '請輸入你遇到的問題',
  Search: '搜索',
  //Product
  //nav
  Stocks: '股票',
  Options: '期權',
  Funds: '基金',
  Bonds: '債券',
  Futures: '期貨',
  SpotCurrencies: '外匯交易',
  Metals: '貴金屬',
  ETFs: 'ETFs',
  HedgeFunds: '對沖基金',
  Portfolios: '投資組合',
  //Stocks
  Useyourfinger: '手中所握，心中所想！',
  Stockline_1: '這裡你能買到全球知名公司的股票',
  Stockline_2: '這裡你能最快的掌握來自於全球的新聞資訊',
  Stockline_3: '這裡你的投資能自由的在全球投放',
  Stockline_4: '這裡你能享受到來自全球券商的高端服務',
  Stockline_5: '這裡你能享受低廉交易成本',
  Stockline_6: '這裡你能享受最安全的資訊服務',
  Stockline_7: '這裡你能使用最專業的資料指標',
  Slideyourfinger: '滑動手指，加入我們',
  JoinUs: '加入我們',
  Important: '重要提示',
  ImportantText: '您的交易必須遵守各國的規定，他們在交易規則及成本上都不同，具體規則及成本請參照各國相關規定，您可以自由選擇不同的交易商進行交易',
  //stocks-line-2
  USAmarkets: '北美市場',
  AUmarkets: '澳洲市場',
  JPmarkets: '亞洲市場',
  USAmarketsText1: '我們接入的是美國著名券商Interactive Brokers的API,您的交易將通過IB完成以低至美國股票每股$0.005（全包）的傭金交易股票。',
  USAmarketsText2: '以每1,000美元8美分左右（全包）的成本獲得最佳價格執行。',
  USAmarketsText3: '通過我們的股票收益提升計畫將您全額持有的股票出借給其他交易者，創造收益。',
  AUmarketsText1: '我們接入的是澳大利亞著名券商Jet Profit(AU)的API,您的交易將通過JP完成',
  AUmarketsText2: '澳大利亞股票每股$0.005（全包）的傭金交易股票。',
  AUmarketsText3: '你同時擁有澳洲和新西蘭的優質投資機會',
  JPmarketsText1: '我們接入的是日本著名券商野村證券的API,您的交易將通過IB完成',
  JPmarketsText2: '全亞洲最低廉的交易成本',
  JPmarketsText3: '活躍的亞洲市場帶給你更多的交易機會和活動',
  //OPTION
  option_title1: '支付更低的傭金，',
  option_title2: '獲取潛在更高的收益!',
  option_title3: '對於美國期權，我們的傭金為每份合約$0.15到$0.70美元',
  option_title4: '低頻和高頻交易者可自選計費方式：',
  option_title5: '固定式 – 低頻交易者可選擇按合約支付低廉的固定傭金，該傭金已包括所有費用。',
  option_title6: '階梯式 – 活躍交易者可選擇按交易支付更低傭金，且可能因較大的交易量獲得交易所折扣。',
  //FUTURE
  future_title1: '廣泛的產品和地理覆蓋面',
  future_title2: '您可在北美、歐洲和亞洲35多個金融市場使用以',
  future_title3: '下期貨合約對沖頭寸風險或從價格變動中獲利：',
  future_title4: '農業——畜牧、穀物及其它貨幣',
  future_title5: '軟性大宗商品——咖啡、白糖、可哥及其它',
  future_title6: '能源——原油、天然氣及其它',
  future_title7: '金屬——貴金屬、基礎金屬及其它',
  future_title8: '股票指數',
  future_title9: '波動率指數',


  //Warning Risk
  RiskWarning: '風險提示',
  Warning1: '您的帳戶預設情況下不會開通高風險或其他杠杆類產品，您需要額外開通功能',
  Warning2: '諸如：期貨，期權，外匯等交易屬於保證金類杠杆交易，在您開通交易功能前必須按照美國法律閱讀《風險揭示書》並簽署',
  Warning3: '同時您必須成為根據美國證券交易委員會(SEC)的規則被認定為認可投資者和合格購買者',
  Warning4: '您需要根據法律填寫您的投資經歷和資產情況通過資格認定，我們才能為您開通此類服務',
  Warning5: '根據產品的地區，您可能需要滿足當地法律要求才能進行購買',
  Warning6: '如果需要幫助，您可以聯繫我們的客服人員',
  //Funds
  Funds1: '26,450至來自世界各地的基金',
  Funds2: '互動經紀共同基金市場為我們在世界各地的客戶以低成本提供大量資金。該市場提供來自超過285個基金家族的26450只免費基金，客戶來自200多個國家和地區，其中包括許多知名基金家族，包括安聯(Allianz)、美國基金(American funds)、貝萊德(BlackRock)、富達(Fidelity)、佛蘭克林鄧普頓(Franklin Templeton)、Invesco、MFS和PIMCO的基金。',
  Funds3: '低成本',
  Funds4: '與大多數公司不同，Finger Trading從不收取託管費。',
  Funds4_1: '超過8300個基金不收取交易費用，美國以外的收費基金收取4.95歐元(或同等價格)。',
  Funds4_2: 'Finger和這個市場是無關的並且沒有利益衝突，因為我們不提供自營基金',
  Funds4_3: '基金價值包括在股本與貸款的價值，以增加客戶保證金購買力。',
  Funds5: '你是買基金的新手?',
  Funds6: '這個指南告訴你如何選擇基金，降低成本和建立你自己的投資組合。',
  Funds7: '重要資訊',
  Funds8: "與現金不同的是，投資的價值和收益可能會上升也可能會下降，所以你得到的回報可能會低於你的投資。我們的服務是為那些樂於做出自己投資決定的投資者而設計的。如果你不確定投資是否合適，請諮詢意見。稅收規則可能會改變，任何福利的價值都取決於你的個人情況。",
  //Bonds
  Bonds1: '使用我們的免費債券搜索工具，看看你能節省多少',
  Bonds2: '享受低廉且完全透明的債券傭金，債券價格沒有加價。購買美國政府證券，26356個公司債券，993,411個市政證券和25,069張信用違約或掉期債券。',
  Bonds3: '提供深度和全方位的服務',
  Bonds4: 'Finger提供多種固定收益債券，以滿足您的交易需求。交易公司，市政，或國債全部從一個綜合帳戶和單一交易螢幕。',
  Bonds5: 'Self-Service Access',
  Bonds6: 'Finger提供直接的市場准入，包括美國、加拿大和歐洲的公司債券、美國政府證券、美國市政債券、亞洲和歐洲的1900 +非美國主權債券、歐元計價的政府債券、固定收益期貨和固定收益期權，所有這些都來自一個單一的綜合帳戶。',


}
