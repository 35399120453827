<template>
  <div id="app">
    <follow-menu/>
    <nav-bar></nav-bar>
    <router-view v-slot="{Component}">
      <keep-alive>
        <transition :name="animation">
          <component :is="Component"/>
        </transition>
      </keep-alive>
    </router-view>

  </div>
</template>

<script>

import { ref, watch } from 'vue'
import { useRoute } from "vue-router";
import FollowMenu from '@/components/followMenu/FollowMenu'
import TotalUser from '@/components/footer/TotalUser.vue'
import NavBar from '@/components/navBar/NavBar.vue'

export default {
  name:'App',
  components: {
    NavBar,
    FollowMenu,
    TotalUser,
  },
  data(){
    return{

    }
  },
  setup(){
    const route = useRoute();
    const animation = ref('slide');
    watch(route , ()=>{
      animation.value = route.meta.index == 1 ? 'slide-right' : 'slide-left'
    })
    return {
      animation
    }
  }
}
</script>

<style>
@import url(./assets/css/base.css);
#app{
  width: 100vw;
  height: 100vh;
  background: var(--main-bg-color);
  overflow: hidden;
}
.router-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.router-view{
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active{
    height: 100%;
    will-change: transform;
    transition: all 500ms cubic-bezier(.55,0,.1,1);
    position: absolute;
    backface-visibility: hidden;
}
.slide-right-enter-active{
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active{
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-enter-active{
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active{
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

</style>

