export const lang = {
    //---------------------按鈕-------------------------
    back: 'Back',
    save: 'Save',
    //--------------------navbar------------------------
    service: 'SERVICE',
    product: 'PRODUCT',
    support: 'SUPPORT',
    trading: 'TRADING',
    promotions: 'PROMOTIONS',
    //-----------------------表單-----------------------
    //登錄相關
    openAccount: 'Open Account',
    joinUs: 'Join Us',
    logout: 'Logout',
    login: 'Login',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    verificationCode: 'Verification Code',
    CheckEmail: 'Please Check Your Mailbox',
    enterEmail: 'Please Enter Your Email Address',
    enterPassword: 'Please Enter Your Password',
    RememberMe: 'Remember Me',
    forgotPasswoed: 'Forgot Passwoed?',
    enterBroker: 'Enter Your Broker Number',
    email: 'Email',
    password: 'Password',
    userCenter: 'User Center',
    //-------------------登錄相關文字------------------------
    welcomeBack: 'Welcome Back',
    hellothere: 'Hello there,',
    loginText1: 'Enter your personal details and start journey with us',
    loginText2: 'Encountered a problem?',
    loginText3: 'Dont hane an acount?',
    loginText4: 'Download APP:',
    //-------------------註冊相關文字------------------------
    signUpText1: 'Welcome To Join Us',
    signUpText2: 'To Keep Connected with us please login with your personal info',
    dontAccount: 'Dont have an account?',
    alreadyAccount: 'Already have an account?',
    //---------------------設定界面-------------------------
    generalProfole: 'General Profole',
    personalInformation: 'Personal Information',
    security: 'Security Setting',
    investmentPreference: 'Investment Preference',
    brokersSetting: 'Brokers Setting',

    generalProfoleText: '完成个性化设定将享受定制型服务并保障账户安全，快来尝试吧！',
    personalInformationText: 'You can modify your personal information here',
    securityText: 'Full security Settings can make your account more secure',
    investmentPreferenceText: 'Setting your investment preferences helps you better control your investment risk',
    brokersSettingText: 'You can freely choose your favorite broker here, enjoy their quality service',
    //---------------------個人設定-------------------------
    avatar: 'Avatar:',
    nickName: 'Nick Name:',
    birth: 'Birth',
    gender: 'Gender',
    male: 'MALE',
    female: 'FEMALE',
    phone: 'Phone',
    emailSetting: 'E-mail Setting',
    commonNotice: 'Common Notice',
    commonNotice1: '我願意接收finger的廣告及活動通知',
    commonNotice2: '我希望finger在我關注的產品達到預期價格時通知我',
    commonNotice3: '我持倉的產品發生超過10%變動時候提示我',
    securityNotice: 'Security Notice',
    securityNotice1: '在我登錄finger時提示我',
    securityNotice2: '異常的IP地址登錄',
    tradingNotice: 'Trading Notice',
    tradingNotice1: '在每次成功交易時通知我',
    tradingNotice2: '在每次賬戶金額有變化時通知我',
    //---------------------安全設置-------------------------
    security1: 'Setting your investment preferences helps you better control your investment risk',
    tWOSTEP: 'TWO-STEP VERIFICATION',
    commonEquipment: 'Common Equipment',
    passwordHint: 'Password Hint',
    changePassword: 'Change Password:',
    changeEmail: 'Change Email:',
    changeMobile: 'Change Mobile:',
    privacySetting: 'Privacy Setting',
    privacySetting1: 'Make Your Email Public',
    privacySetting2: 'Make Your Base Information Public',
    privacySetting3: 'Make Your Base Trading Public',
    privacySetting4: 'Make Your Base Holding Public',

}
