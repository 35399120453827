import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SvgIcon from '@/components/SvgIcon/SvgIcon'
import eventBus from 'vue3-eventbus'
import ElementPlus from 'element-plus'
import VueI18n from './lang'
import 'element-plus/lib/theme-chalk/index.css'


//SVG解析
import "./utils/svg"
const app = createApp(App);
app
  .use(VueI18n)
  .use(ElementPlus)
  .use(eventBus)
  .use(store)
  .use(router)
  .component("svg-icon",SvgIcon)
  .mount("#app");
