export const lang = {
    //---------------------按鈕-------------------------
    back: '後退',
    save: '保存',
    //导航
    service: '服務內容',
    product: '產品詳情',
    support: '技術支持',
    trading: '開始交易',
    promotions: '活动促销',
//-----------------表單----------------------
    //登錄相關
    openAccount: '開設賬戶',
    joinUs: '加入我們',
    logout: '退出登錄',
    login: '開始登錄',
    signIn: '登錄',
    signUp: '註冊會員',
    verificationCode: '驗證碼',
    CheckEmail: '請前往您的郵箱獲取驗證碼',
    enterEmail: '請輸入您的電子郵件',
    enterPassword: '請輸入您的密碼',
    RememberMe: '記住我',
    forgotPasswoed: '忘記密碼?',
    enterBroker: '請輸入您經紀人的編號',
    email: '電子郵件',
    password: '密碼',
    userCenter: '用戶中心',
    //-------------------登錄相關文字------------------------
    welcomeBack: '歡迎回來',
    hellothere: '您好',
    loginText1: '請輸入您的個人信息加入我們吧',
    loginText2: '遇到了問題？',
    loginText3: '已經擁有一個賬號?',
    loginText4: '下載應用:',
    //-------------------註冊相關文字------------------------
    signUpText1: '歡迎您加入我們',
    signUpText2: '請登錄您的個人賬號加入我們',
    dontAccount: '還沒有賬號?',
    alreadyAccount: '已經擁有一個賬號?',
    //---------------------設定界面-------------------------
    generalProfole: '一般設定',
    personalInformation: '個人信息',
    security: '安全設定',
    investmentPreference: '投資偏好',
    brokersSetting: '經紀設定',

    generalProfoleText: '完成个性化设定将享受定制型服务并保障账户安全，快来尝试吧！',
    personalInformationText: '您可以在這裡修改您的個人信息',
    securityText: '完整的安全設定能夠更有效的保護您的賬戶安全',
    investmentPreferenceText: '完成您的投資偏好可以讓我們更好的服務您，並有效的幫助您控制您的風險',
    brokersSettingText: '您可以在這裡綁定您最愛的經紀商，盡情的享受他們的服務吧',
    //---------------------個人設定-------------------------
    avatar: '大頭貼:',
    nickName: '暱稱:',
    birth: '生日',
    gender: '性別',
    male: '男性',
    female: '女性',
    phone: '聯絡電話',
    emailSetting: '電子郵箱設定',
    commonNotice: '常用通知',
    commonNotice1: '我願意接收finger的廣告及活動通知',
    commonNotice2: '我希望finger在我關注的產品達到預期價格時通知我',
    commonNotice3: '我持倉的產品發生超過10%變動時候提示我',
    securityNotice: '安全通知',
    securityNotice1: '在我登錄finger時提示我',
    securityNotice2: '異常的IP地址登錄',
    tradingNotice: '交易通知',
    tradingNotice1: '在每次成功交易時通知我',
    tradingNotice2: '在每次賬戶金額有變化時通知我',
    //---------------------安全設置-------------------------
    security1: '完整配置您的安全設定可以有效的幫助您保護您的賬戶',
    tWOSTEP: '二步驗證',
    commonEquipment: 'Common Equipment',
    passwordHint: '密碼問題',
    changePassword: '更換密碼:',
    changeEmail: '更換電子郵箱:',
    changeMobile: '更換移動電話:',
    privacySetting: '隱私設定',
    privacySetting1: '公開我的電子信箱',
    privacySetting2: '公開我的基本信息',
    privacySetting3: '公開我的交易記錄',
    privacySetting4: '公開我的持倉信息',
//-----------------廣告----------------------
}
