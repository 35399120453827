import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'

const store = new Vuex.Store({
  //引用vuex-persistedstate对state数据持久化
  plugins: [
    persistedState({ storage: window.sessionStorage })
  ],

  // this.$store.state.***
  state: {
    email: '',
    password:"",
  },

  mutations: {
    //删除state this.$store.commit('remover', 'key1');
    remover (state, key) {
      state[key] = '';
    },

    //更新state this.$store.commit('upDate', {key1: 'value3'});
    upDate (state, obj) {
      let key = Object.keys(obj)[0];
      state[key] = obj[key];
    },

    //添加state信息
      addUserInfo (state, obj){
          state.userInfo = obj;
      },
  },

});

export default store
