<template>
  <div id="navbar">
    <div class="navBarBack"></div>
    <div class="logoBox" @click="goIndex()">
      <svg id="_1" data-name="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.15 115.38">
        <title>finger2</title>
        <path fill="currentColor" d="M-240.5,104h-11.67q0-9-4.14-13.31a13.4,13.4,0,0,0-10.09-4.35,13.35,13.35,0,0,0-10,4.29A14.38,14.38,0,0,0-280.51,101v19.6h17v12.1h-17v19.36h-11.66V101.14a26.38,26.38,0,0,1,7.58-19,24.38,24.38,0,0,1,18.2-7.86,24.21,24.21,0,0,1,18.43,8Q-240.5,90.25-240.5,104Z" transform="translate(292.17 -74.28)"/>
        <path fill="currentColor" d="M-176,159.78q0,13.91-7.5,21.78a26,26,0,0,1-19.24,8.1,25.9,25.9,0,0,1-19-7.86,25.9,25.9,0,0,1-7.86-19V74.28h12.1v56.95h17.79v12.1h-17.8v19.48a14.13,14.13,0,0,0,4.36,10.47,14.29,14.29,0,0,0,10.41,4.3,14,14,0,0,0,10.41-4.36q4.23-4.36,4.23-13.43H-176Z" transform="translate(292.17 -74.28)"/>
        <path fill="currentColor" d="M-256.27,109.15V173.1h-11.85a12.25,12.25,0,0,1-12.23-12.23v-15h-11.82v19.74a24.08,24.08,0,0,0,24.08,24.08h27V109.14h-15.18Z" transform="translate(292.17 -74.28)"/>
        <path fill="currentColor" d="M-188.58,100.47v52.82h11.79V98.37a24.08,24.08,0,0,0-24.08-24.08h-7V86.24h7C-193,86.24-188.58,92.63-188.58,100.47Z" transform="translate(292.17 -74.28)"/>
      </svg>
      <div class="maskNavBar" id="maskNavBar"></div>
      <div class="reMask"></div>
      <div class="logoText">Finger Trading</div>
    </div>

      <div id="trading" @click="goTrading()">{{$t(`lang.trading`)}}</div>
    <div class="menu">
      <div id="promotions" class="navbarMenuItem" @click="goPromotions()">{{$t(`lang.promotions`)}}</div>
      <div id="product" class="navbarMenuItem" @click="goProduct()">{{$t(`lang.product`)}}</div>
      <div id="service" class="navbarMenuItem" @click="goService()">{{$t(`lang.service`)}}</div>
      <div id="support" class="navbarMenuItem" @click="goSupport()">{{$t(`lang.support`)}}</div>
      <div class="userInfoBox">
        <div id="btnlogin" class="btnlogin" v-if="!userInfo">
          <btn-login @click="goLogin()">{{$t(`lang.login`)}}</btn-login>
        </div> 
        <div class="userInfo" v-if="userInfo" @click="goUserCenter()">
          <div class="nickname">{{userInfo.nickName}}</div>
          <img class="userImg" :src="userInfo.avatar">
        </div> 
        <div class="userInfoMenu">
          <li @click="openAccount()">{{$t(`lang.openAccount`)}}</li>
          <li @click="employeApply()">{{$t(`lang.joinUs`)}}</li>
          <li @click="goJoinUs()">Join US2</li>
          <li @click="logout()">{{$t(`lang.logout`)}}</li>
        </div>
      </div>
      <div class="btnBox">
        <div class="btnlang">
          <svg-icon :name="currentLang"/>
        </div>
        <div class="langlist">
          <div class="langListItem" @click="changeLangEn">
            <svg-icon name="uk"/>
          </div>
          <div class="langListItem" @click="changeLangCn">
            <svg-icon name="taiwan"/>
          </div>
          
        </div>
      </div>

    </div>

    <input type="checkbox" id="res-menu">
    <label for="res-menu" class="menu_burger" :class="{open: isActive}" @click="burger()">
      <div class="burger"></div>
    </label>
    <div class="phone_menu">
      <div id="trading" class="navbarMenuItem" @click="goTrading()">{{$t(`lang.trading`)}}</div>
      <div id="promotions" class="navbarMenuItem" @click="goPromotions()">{{$t(`lang.promotions`)}}</div>
      <div id="service" class="navbarMenuItem" @click="goService()">{{$t(`lang.service`)}}</div>
      <div id="product" class="navbarMenuItem" @click="goProduct()">{{$t(`lang.product`)}}</div>
      <div id="support" class="navbarMenuItem" @click="goSupport()">{{$t(`lang.support`)}}</div>
      <div class="menuItem">LANGUAGE</div>
    </div>
  </div>
</template>

<script>

import BtnLogin from '@/components/button/BtnLogin'
import cookie from "js-cookie"
export default {
  name:'NavBar',
  components: {
    BtnLogin,
  },
  data() {
    return {
      isActive: false,
      userInfo: null,
      currentLang:'uk'
    }
  },
  mounted(){
    this.getUserInfo()
  },
  methods:{
    goIndex(){
      this.$router.push('/index')
    },
    burger(){
      this.isActive = !this.isActive
    },
    goLogin(){
      this.$router.push('/login')
      
    },
    goService(){
      this.$router.push('/service')
    },
    goSupport(){
      this.$router.push('/support')
    },
    goProduct(){
      this.$router.push('/product')
    },
    goTrading(){
      this.$router.push('/trading')
    },
    getUserInfo(){
      let userInfor = cookie.get('userInfo')
      if(!userInfor) {
        this.userInfo= null
        return
      }
      userInfor = JSON.parse(userInfor)
        this.$store.state.userBaseInfo = userInfor
        this.userInfo = userInfor
    },
    goUserCenter(){
      this.$router.push("/usercenter")
    },
    goPromotions() {
      this.$router.push("/usercenter")
    },
    logout(){
      //清除cookie
      cookie.set('userInfo','')
      window.location.href = '/'
    },
    employeApply() {
      this.$router.push("/employeeapply")
    },
    openAccount(){
      this.$router.push("/openaccountapply")
    },
    goJoinUs(){
      this.$router.push("/joinus")
    },
    changeLangEn() {
      this.$i18n.locale = 'en'
      this.currentLang='uk'
    },
    changeLangCn() {
      this.$i18n.locale = 'cn'
      this.currentLang='taiwan'
    },
  }

}
</script>

<style scoped>
.navBarBack{
  background: #141414;
  opacity: .1;
  width: 100vw;
  height: 54px;
  position: absolute;
}
#navbar{
  display: flex;
  width: 100vw;
  height: 54px;
  position: absolute;
  top: 0;
  left: 0;
  color: aliceblue;
  z-index: 99;
  align-items: center;

}
.userInfo:hover~.userInfoMenu{
  display: block;
}
.userInfoMenu:hover.userInfoMenu{
  display: block;
}
.btnlang{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
}
.btnBox{
  height: 54px;
  display: flex;
  justify-self: center;
  align-items: center;
}
.btnlang:hover~.langlist{
  height: auto;
}
.langlist{
  position: absolute;
  overflow: hidden;
  height: 0;
  top: 48px;
}
.langlist:hover.langlist{
  height: auto;
}
.langListItem{
  height: 40px;
}
.userInfoMenu{
  position: absolute;
  width: 100%;
  background: #8a0c0c;
  height: auto;
  max-height: 400px;
  overflow: hidden;
  display: none;
  transition: .4;
  top: 54px;
  text-align: center;
  color: antiquewhite;
}
.userInfoMenu li:hover{
  background: bisque;
  cursor: pointer;
}
#res-menu{
  display: none;
}
.phone_menu{
  display: none;
}
.maskNavBar{
  width: 260px;
  height: 50px;
  position: absolute;
  left: 220px;
  background: radial-gradient(#191b27,#1e1f25);
  top: 0;
  z-index: 101;
  animation: maskMove .5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: .6s;
  display: block;
}

.reMask{
  width: 220px;
  height: 54px;
  position: absolute;
  left: 0px;
  background: radial-gradient(#191b27,#1e1f25);
  top: 0;
  z-index: 101;
  animation: maskMove2 .5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.6s;
  border: none;
}
.logoBox{
  color: aliceblue;
  width: 40%;
  line-height: 54px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  transition: .6s;
}
.logoBox:hover{
  color: rgb(211, 39, 39);
  cursor: pointer;
}
.logoBox svg{
  height: 36px;
  margin-top: 12px;
  min-height: 22px;
  left: 410px;
  position: absolute;
  z-index: 111;
  animation: logoMove 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: .6s;
}
.logoText{
  line-height: 60px;
  margin-left: 230px;
  font-size: 26px;
  font-weight: 900;
  z-index: 100;
  opacity: 0;
  animation: textMove 1s ease;
  animation-fill-mode: forwards;
  animation-delay: .8s;
}

#btnlogin{
  position: relative;
  animation: navMenuMove .6s ease-in;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  box-sizing: border-box;
  opacity: 0;
}
.menu{
  position: relative;
  width: 46%;
  display: flex;
  height: 100%;
  color: #fff;
  line-height: 54px;
  align-items: center;
  justify-content: space-between;
}
.navbarMenuItem{
  flex: 1;
  opacity: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  transition: .4s;
  text-align: center;
}
.menu div:hover{
  color: #4a5748;
  cursor: pointer;
  font-size: 16px;
}
#trading{
  width: 100px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #fff;
  animation: navMenuMove 1s ease-in;
  animation-delay: .8s;
  animation-fill-mode: forwards;
}
#promotions{
  animation: navMenuMove 1s ease-in;
  animation-delay: .8s;
  animation-fill-mode: forwards;
}
#service{
  animation: navMenuMove 1s ease-in;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
#product{
  animation: navMenuMove 1s ease-in;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
}
#support{
  animation: navMenuMove 1s ease-in;
  animation-delay: 1.4s;
  animation-fill-mode: forwards;
}
.viewMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0;
  transition: .4s;
  z-index: -1;
  
}
.activeMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: .7;
  transition: .4s;
  transition-delay: .8s;
  z-index: 101;
  cursor: pointer;
}
.userInfoBox{
  position: relative;
  display: flex;
  align-items: center;
  height: 54px;
  min-width: 260px;
  justify-content: center;
  padding: auto;
}
.userInfo{
  position: relative;
  display: flex;
  max-width: 260px;
  height: 54px;
  align-items: center;
}
.userInfo:hover{
  color: #8a0c0c;
}
.nickname{
  margin-right: 20px;
  font-family: "Hammer";
}
.nickname:hover{
  color: #8a0c0c;
}

.userImg{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  overflow: hidden;
}
/**動畫內容*/
@keyframes logoMove {
  0%{
    transform: rotate(-10deg);
    left: 410px;
  }
  60%{
    transform: rotate(-10deg);
    left: 160px;
  }
  100%{
    transform: rotate(0deg);
    left: 180px;

  }
}
@keyframes maskMove {
  0%{
    left: 420px;
    opacity: 1;

  }
  90%{
    opacity: 1;
  }
  100%{
    left: -2000px;
    opacity: 0;
    display: none;
  }
}
@keyframes maskMove2 {
  0%{
    left: 0px;
    opacity: 1;

  }
  90%{
    opacity: 1;
  }
  100%{
    left: -100px;
    opacity: 0;
  }

}
@keyframes navMenuMove{
  0%{
    transform: translateY(-30px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes textMove {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.v-enter-from{
  opacity: 0;
}
.v-enter-active{
  transition: opacity 2s ease-in;
}
.v-enter-to{
  opacity: 1;
}

@media screen and (max-width: 1200px){
  .menu{
    font-size: 12px;
  }
  .logoBox{
    color: aliceblue;
    width: 40%;

  }
  .logoBox svg{
    height: 28px;
    margin-top: 15px;
    min-height: 22px;
    left: 410px;
  }
  .logoText{
    font-size: 20px;
  }
}
@media screen and (max-width: 950px){
  #navbar{
    display: flex;
    width: 100vw;
    height: 50px;
    position: relative;
    top: 0;
    left: 0;
    color: aliceblue;
    z-index: 99;
  }
  .logoBox{
    width: 50%;
  }
  .logoBox svg{
    height: 28px;
    margin-top: 10px;
    min-height: 22px;
    left: 30px;
    animation: none;
  }
  .logoText{
    line-height: 50px;
    margin-left: 70px;
    font-size: 16px;
    font-weight: 900;
    z-index: 100;
    opacity: 1;
    animation: none;
    animation-fill-mode: forwards;
    animation-delay: .1s;
  }
  .mask,.reMask{
    display: none;
  }
  .menu{
    display: none;
  }
  .menu_burger{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all .5s linear;
    margin-left: 34%;
    z-index: 11;
  }
  .burger{
    width: 36px;
    height: 2px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 5px rgb(255, 101, 47,.2);
    transition: all .5s ease-in-out;
  }
  .burger::before,.burger::after{
    content: '';
    position: absolute;
    width: 36px;
    height: 2px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 5px rgb(255, 101, 47,.2);
    transition: all .5s ease-in-out;
  }
  .burger::before{
    transform: translateY(-10px);
  }
  .burger::after{
    transform: translateY(10px);
  }
  /**Burger animation */
  .open .burger{
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
    z-index: 11;
  }
  .open .burger::before{
    transform: rotate(45deg) translate(35px,-35px);
    z-index: 11;
  }
  .open .burger::after{
    transform: rotate(-45deg) translate(35px,35px);
    z-index: 11;
  }
  .phone_menu{
    display: block;
    position: absolute;
    background: #8a0c0c;
    width: 100vw;
    height: 100vh;
    top: 54px;
    left: 101%;
    text-align: center;
    transition: .5s;
    z-index: 9;
    border-bottom: 1px solid #000;
  }
  .fristOne{
    display: block;
    height: 80px;
    color: #fff;
    font-size: 20px;
    line-height: 80px;
    border-bottom: 1px solid rgb(82, 47, 47);
  }
  #res-menu:checked ~ .phone_menu{
    left: 0;
  }
  .menuItem{
    display: block;
    height: 80px;
    color: #fff;
    font-size: 20px;
    line-height: 80px;
    text-align: center;
    border-bottom: 1px solid rgb(82, 47, 47);
  }
  .menuItem a{
    color: #fff;
  }
  .animate__bounceIn{
    animation: none;
  }
  .activeMask{
    animation: none;
    animation-delay: none;
    transition: none;
  }
}
</style>
