<template>
  <div id="btnLogin">
    <slot></slot>
  </div>

</template>

<script>
export default {
  name:'BtnLogin',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
#btnLogin{
  position: relative;
  height: 30px;
  width: 100px;
  background: var(--main-color-red);
  display: flex;
  overflow: hidden;
  border-radius: 15px;
  text-align: center;
  line-height: 30px;
  transition: .4s;
  align-items: center;
  justify-content: center;
}
#btnLogin:hover{
  background: var(--main-color-blue);
  color: aliceblue;
}

</style>
