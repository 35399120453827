import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/individuals/LoginView.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/service/Service.vue'),
    children:[
      {
        path: '',
        component: () => import('../views/service/ServiceIndex.vue'),
      },
      {
        path: 'personal',
        component: () => import('../views/service/personal/PersonalService.vue'),
      },
      {
        path: 'personal2',
        component: () => import('../views/service/personal/PersonalTwo.vue'),
      },
      {
        path: 'personal3',
        component: () => import('../views/service/personal/PersonalThree.vue'),
      },
    ]
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/support/Support.vue')
  },
  {
    path: '/employeeapply',
    name: 'Employee Apply',
    component: () => import('@/views/login/empolyee/EmployeeApplyForm.vue')
  },
  {
    path: '/find-password',
    name: 'Find Password',
    component: () => import('@/views/support/findPassword/FindPassword.vue')
  },
  {
    path: '/usercenter',
    name: 'UserCenter',
    component: () => import('../views/usercenter/UserCenter.vue'),
    children:[
      {
        path: '',
        component: () => import('../views/usercenter/home/Home.vue'),
      },
      {
        path: 'home',
        name: 'Home',
        icon: 'home',
        component: () => import('../views/usercenter/home/Home.vue'),
      },
      {
        path: 'setting',
        name: 'setting',
        icon: 'setting',
        component: () => import('../views/usercenter/setting/SettingIndex.vue'),
        children:[
          {
            path: '',
            name: 'setting',
            icon: 'setting',
            component: () => import('../views/usercenter/setting/Setting.vue'),
          },
          {
            path: 'personal',
            name: 'personal',
            icon: 'personal',
            component: () => import('../views/usercenter/setting/personalInfo/PersonalInfo.vue'),
          },
          {
            path: 'security',
            name: 'Security',
            icon: 'security',
            component: () => import('../views/usercenter/setting/security/SecuritySetting.vue'),
          },
          {
            path: 'investment',
            name: 'investment',
            icon: 'investment',
            component: () => import('../views/usercenter/setting/investmentPreference/InvestmentPreference.vue'),
          },
          {
            path: 'brokers',
            name: 'brokers',
            icon: 'brokers',
            component: () => import('../views/usercenter/setting/brokersSetting/BrokersSetting.vue'),
          },
          {
            path: 'changePassword',
            name: 'Change Password',
            icon: 'lock',
            component: () => import('../views/usercenter/setting/security/changePassword/ChangePassword.vue'),
          },
          {
            path: 'changeEmail',
            name: 'Change Email',
            icon: 'email',
            component: () => import('../views/usercenter/setting/security/changeEmail/ChangeEmail.vue'),
          },
        ]
      },
    ]
  },
  {
    path: '/joinus',
    name: 'JoinUsIndex',
    component: () => import('../views/login/joinUs/JoinUsIndex.vue'),
    children:[
      {
        path: '',
        name: 'JoinUs',
        component: () => import('../views/login/joinUs/JoinUs.vue'),
      },
      {
        path: 'borker',
        name: 'Borker',
        component: () => import('../views/login/joinUs/borker/Borker.vue'),
      },
      {
        path: 'borkers',
        name: 'Borkers',
        component: () => import('../views/login/joinUs/borkers/Borkers.vue'),
      },
      {
        path: 'agency',
        name: 'Agency',
        component: () => import('../views/login/joinUs/agency/Agency.vue'),
      },
    ]
  },
  {
    path: '/openaccountapply',
    name: 'Open Account Apply',
    component: () => import('../views/login/openAccount/OpenAccountApplyForm.vue')
  },
  {
    path: '/twoStepLogin',
    name: 'Two Step Login',
    component: () => import('../views/login/individuals/TwoStepLogin.vue')
  },
  {
    path: '/locked',
    name: 'Locked',
    component: () => import('../views/login/lockView/LockedView.vue')
  },
  {
    path: '/trading',
    name: 'Trading',
    component: () => import('../views/tradingSystem/Trading.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
